import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

import BackgroundImage from 'gatsby-background-image'
import { StaticQuery, graphql } from "gatsby"


export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <StaticQuery
      query={graphql`
        query BlogIndex {
          imageSharp(fluid: {originalName: {eq: "home-jumbotron.jpg"}}) {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      `}
      render={data => (
      <Layout>
        <BackgroundImage
          Tag="section"
          className="full-width-image-container margin-top-0"
          fluid={data.imageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <h1
            className="has-text-weight-bold is-size-1 jumbo-overlay"
          >
            Latest Stories
          </h1>

        </BackgroundImage>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )}
    />
    )
  }
}
